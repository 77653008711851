@import "tools";
html,
body,
.grid-container {
  height: 100%;
  margin: 0rem;
}

.grid-container * {
  //  border: 1px solid red;
  position: relative;
}

.grid-container *:after {
  //  content:attr(class);
  position: absolute;
  top: 0;
  left: 0;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0fr auto auto 0.1fr;
  grid-template-areas:
    "header"
    "content_a"
    "content_b"
    "footer";
}


@include respond-above(sm) {
  .grid-container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0fr 1fr 0.1fr;
    column-gap: 2rem;
    row-gap: 2rem;
    grid-template-areas:
      "header header"
      "content_a content_b"
      "footer footer";
  }
}
.content_a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: content_a;
  
}
.content_b {
  grid-area: content_b;
  min-height: 300px;
}

.content_a,
.content_b {
  .Aligner {
    height: 100%;
    display: flex;
    align-items: left;
    justify-content: left;
    border-bottom: 1px solid black;
  }

  .Aligner-item {
    max-width: 90%;
  }

  .Aligner-item--top {
    align-self: flex-start;
  }

  .Aligner-item--bottom {
    align-self: flex-end;
  }
}

.footer {
  p {
    
    font-size: 1rem;
    text-align: left;
  }
}
.header {
  border-bottom: 1px solid black;
  a{
    text-decoration: none;
  }
  grid-area: header;
}
