@font-face {
  font-family: "HKGrotesk-Light";
  src: url("HKGrotesk-Light.otf");
  font-display: swap;
}

@font-face {
  font-family: "HKGrotesk-SemiBold";
  src: url("./HKGrotesk-SemiBold.otf");
  font-display: swap;
}

@font-face {
  font-family: "HKGrotesk-Bold";
  src: url("./HKGrotesk-Bold.otf");
  font-display: swap;
}

@font-face {
  font-family: "HKGrotesk-Medium";
  src: url("HKGrotesk-Medium.otf");
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');