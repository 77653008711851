@import "fonts";
@import "tools";
@import "grid";
$primary-font-color: rgba(0, 0, 0, 0.712);
$bg-color-a: rgba(255, 255, 255, 0.603);
$bg-color-b: rgba(32, 119, 141, 0.603);
$bg-color-default: rgba(
  141.81761037810867,
  199.77368215993206,
  193.9837410352609,
  0.2
);

h1,
p,
a,h4
 {
  font-family: "HKGrotesk-Medium", sans-serif;
  color: $primary-font-color;
  font-display: swap;
  font-weight: 200;
  @include set-fontsize(sm);
  @include fadeIn(1s);
}

html {
  width: 100%;
  height: auto;
}
body {
  margin: 1rem;
  background: bg-color-default;
}
.header {
  min-height: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  .contact {
    margin-top: auto;
    margin-bottom: auto;
    align-self: flex-start !important;
  }

  .circle {
    width: 19px;
    height: 19px;
    z-index: 3;
    margin-top: auto;
    margin-bottom: auto;
    background: $primary-font-color;
    border-radius: 50%;

    align-self: flex-end;
  }
}

img {
  display: block;
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
}

@include respond-above(lg) {
  img {
    width: 100%;
    max-width: 600px;
  }
}
.copyright {
  font-size: 0.9rem;
}

@include respond-above(sm) {
  h1,
  p,
  a,
  h2,
  h4 {
    @include set-fontsize(sm);
  }
}

@include respond-above(md) {
  h1,
  p,
  a,
  h2,
  h4 {
    @include set-fontsize(xs);
  }
}

.inner_wrapper {
  margin: 0.5rem;
}

#canvas{
  
  display:block;
  
max-width: 100%;
min-height: 600px;
}

