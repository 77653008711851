$fontvariants: (
  xs: 0.34rem,
  sm: 0.65rem,
  md: 0.8rem,
  lg: 1rem,
  xl: 5rem,
  xxl: 10rem,
);
$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
);

// Get the small breakpoint.
//$breakpoint: map-get($breakpoints, sm);
// Respond above.

@mixin set-fontsize($fontvariant) {
  // If the breakpoint exists in the map.
  @if map-has-key($fontvariants, $fontvariant) {
    // Get the breakpoint value.

    $fontvariant-value: map-get($fontvariants, $fontvariant);
    font-size: calc(#{$fontvariant-value} + 1vw);
    font-size: -moz-calc(#{$fontvariant-value} + 1vw);
    font-size: -webkit-calc(#{$fontvariant-value} + 1vw);
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get(
      $breakpoints,
      $breakpoint
    ); // Write the media query.

    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin setFont($minFont, $varSize) {
  font-size: -moz-calc($minFont + $varSize);
  font-size: -webkit-calc($minFont + $varSize);
  font-size: calc($minFont + $varSize);
}

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin easeOut {
  transition: transform 6.2s ease-in-out;
}

@mixin center_fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate();
}

@mixin center_abs {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin center_vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

// ********************
// fadeIn
// ********************
$prefix: "-moz-", "-webkit-", "-o-", "-ms-", "";

@mixin keyframe-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg);
  }
}

@-moz-keyframes rotate {
  @include keyframe-rotate;
}

@-webkit-keyframes rotate {
  @include keyframe-rotate;
}

@-o-keyframes rotate {
  @include keyframe-rotate;
}

@-ms-keyframes rotate {
  @include keyframe-rotate;
}

@keyframes rotate {
  @include keyframe-rotate;
}

@mixin rotate($arg) {
  $keyframe-name: rotate;
  $duration: $arg;

  @each $p in $prefix {
    #{$p}animation: $keyframe-name $duration;
  }
}

@mixin keyframe-fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  @include keyframe-fadeIn;
}

@-webkit-keyframes fadeIn {
  @include keyframe-fadeIn;
}

@-o-keyframes fadeIn {
  @include keyframe-fadeIn;
}

@-ms-keyframes fadeIn {
  @include keyframe-fadeIn;
}

@keyframes fadeIn {
  @include keyframe-fadeIn;
}

@mixin fadeIn($arg) {
  $keyframe-name: fadeIn;
  $duration: $arg;

  @each $p in $prefix {
    #{$p}animation: $keyframe-name $duration;
  }
}

@mixin keyframe-fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  @include keyframe-fadeOut;
}

@-webkit-keyframes fadeOut {
  @include keyframe-fadeOut;
}

@-o-keyframes fadeOut {
  @include keyframe-fadeOut;
}

@-ms-keyframes fadeOut {
  @include keyframe-fadeOut;
}

@keyframes fadeOut {
  @include keyframe-fadeOut;
}

@mixin fadeOut($arg) {
  $keyframe-name: fadeOut;
  $duration: $arg;

  @each $p in $prefix {
    #{$p}animation: $keyframe-name $duration;
  }
}

@mixin spin {
  @-moz-keyframes spin {
    0% {
      -moz-transform: rotate(0deg);
    }

    100% {
      -moz-transform: rotate(45deg);
    }
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(45deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(45deg);
    }
  }
}

@mixin Opacity($value) {
  $IEValue: $value * 100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $IEValue +
    ")";
  filter: alpha(opacity=$IEValue);
}

@mixin rotateStatic($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

// -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=#{cos($degrees)}, M12=#{-1*sin($degrees)}, M21=#{sin($degrees)}, M22=#{cos($degrees)})";
//    filter:  progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=#{cos($degrees)}, M12=#{-1*sin($degrees)}, M21=#{sin($degrees)}, M22=#{cos($degrees)});
